import { deleteCookie } from "cookies-next";

import { brand, PRODUCTION_BUILD } from "./helpers/envFunctions";
import { log } from "./helpers/logging/logger";
import { TRACKING_EVENTS } from "./helpers/statsigFunctions";
import { ezoicSetIdentity } from "./helpers/ezoicFunctions";
import { handleSessionId, handleUserId } from "./helpers/utilityFunctions";

interface iExperiment {
  name: string;
  value: string;
}

interface iParameters {
  [key: string]: string;
}

export interface iTrackProperties {
  numPagesViewed?: number;
  secondsOnSite?: number;
  id?: string;
  title?: string;
  referrer?: string;
  referer?: string;
  url?: string;
  location?: string;
  md5?: string;
  experiments?: iExperiment[];
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  parameters?: iParameters;
  [key: string]: any;
}

type EventTypes =
  | "conversion"
  | "impression"
  | "engagement"
  | "internal"
  | "pageview"
  | "subscribe";

interface iTrackEvent {
  event: keyof TRACKING_EVENTS;
  properties: {};
  customOverrides: {
    location?: {
      pathname?: string;
    };
  };
}

const SUB_TYPE_MAPPING: { [key in EventTypes]: Set<keyof TRACKING_EVENTS> } = {
  conversion: new Set([
    TRACKING_EVENTS.joke_card_click,
    TRACKING_EVENTS.next_joke_button_click,
    TRACKING_EVENTS.word_grid_click,
    TRACKING_EVENTS.next_word,
    TRACKING_EVENTS.next_quiz_click,
    TRACKING_EVENTS.featured_quiz_click,
    TRACKING_EVENTS.recipe_tile_click,
    TRACKING_EVENTS.explore_more_tile_click,
    TRACKING_EVENTS.chicory_button_click,
    TRACKING_EVENTS.wk_clicked,
    TRACKING_EVENTS.article_tile_click,
    TRACKING_EVENTS.click_display_ad,
    TRACKING_EVENTS.click_video_ad_bottom_right,
    TRACKING_EVENTS.click_tracked_link,
    TRACKING_EVENTS.most_read_article_click,
    TRACKING_EVENTS.recommended_article_click,
  ]),
  engagement: new Set([
    TRACKING_EVENTS.play_sound,
    TRACKING_EVENTS.answer_clicked,
    TRACKING_EVENTS.next_question_click,
    TRACKING_EVENTS.see_results_click,
    TRACKING_EVENTS.print_click,
    TRACKING_EVENTS.download_click,
    TRACKING_EVENTS.pinterest_share_click,
    TRACKING_EVENTS.recipe_tag_click,
    TRACKING_EVENTS.image_carousel_nav,
    TRACKING_EVENTS.promotional_carousel_click,
    TRACKING_EVENTS.scroll_for_more_click,
    TRACKING_EVENTS.wk_closed,
    TRACKING_EVENTS.subscribe_modal_dismissed,
    TRACKING_EVENTS.facebook_share_click,
    TRACKING_EVENTS.twitter_share_click,
    TRACKING_EVENTS.search_open,
    TRACKING_EVENTS.social_embed_click,
    TRACKING_EVENTS.social_media_button_click,
    TRACKING_EVENTS.editors_pick_article_click,
    TRACKING_EVENTS.editors_pick_article,
  ]),
  impression: new Set([
    TRACKING_EVENTS.scroll_depth,
    TRACKING_EVENTS.scroll_depth_desktop,
    TRACKING_EVENTS.scroll_depth_mobile,
    TRACKING_EVENTS.explore_more_scroll,
    TRACKING_EVENTS.wk_accepted,
    TRACKING_EVENTS.wk_adtype,
    TRACKING_EVENTS.subscribe_open,
    TRACKING_EVENTS.ad_div_impression,
    TRACKING_EVENTS.subscribe_impression,
  ]),
  internal: new Set([
    TRACKING_EVENTS.chicory_audit_view,
    TRACKING_EVENTS.chicory_audit_click,
    TRACKING_EVENTS.chicory_audit_grocer,
    TRACKING_EVENTS.fuzzy_match,
    TRACKING_EVENTS.sharesheet_abort,
    TRACKING_EVENTS.sharesheet_button_click,
    TRACKING_EVENTS.sharesheet_failure,
    TRACKING_EVENTS.sharesheet_success,
  ]),
  pageview: new Set([TRACKING_EVENTS.pageview]),
  subscribe: new Set([TRACKING_EVENTS.subscribed]),
};

function getMappedEventType(
  event: keyof TRACKING_EVENTS,
): EventTypes | "unknown" {
  const mappedEvent = Object.entries(SUB_TYPE_MAPPING).find(
    ([, possibleMatches]) => possibleMatches.has(event),
  )?.[0] as EventTypes;
  return mappedEvent ?? "unknown";
}

function getClickserverApiEndpoint() {
  return `https://clickserver.${PRODUCTION_BUILD ? "web" : "staging"}.${
    brand.id
  }.com/events`;
}

const STATSIG_PREFIX = "ss_";
const STATSIG_OVERRIDE_PREFIX = "ss_override_";

const JT_PAGEVIEW_EVENTS = [] as string[];
const JT_MD5_EVENTS = [] as string[];
const JT_TRACK_EVENTS = [] as iTrackEvent[];

class EventTracker {
  _experiments: iExperiment[] = [];
  endpoint = "";
  history: string[] = [];
  md5 = "";
  sha1 = "";
  sha256 = "";
  statsig_userID: string = (this.getCookie("userID") as string) ?? "";
  id = "";
  session_id = "";
  startTime: number | null = null;
  startTimeOnPage: number | null = null;
  currentIframe = "";
  parameters: iParameters = {};
  iframeTracking = () => {
    return;
  };

  constructor() {
    this.handleMd5Hash();
    this.handleSha1Hash();
    this.handleSha256Hash();
    this.parameters = this.handleParameters();
    this.endpoint = getClickserverApiEndpoint();
    this.id = handleUserId();
    this.session_id = handleSessionId();
    this.setIdentity({ md5: this.md5, sha1: this.sha1, sha256: this.sha256 });
  }

  handleParameters(): iParameters {
    const paramsArray = location.search
      .split("&")
      .map((x) => x.split("="))
      .filter((x) => x.length === 2)
      .map((x) => {
        return [x[0].replace("?", ""), x[1]];
      })
      .map((x) => ({
        name: x[0],
        value: x[1],
      }));

    const paramsObj: iParameters = {};
    paramsArray.forEach((x) => {
      paramsObj[x.name] = x.value;
    });

    const storedParams: iParameters = JSON.parse(
      this.getLsWithExpiry("params") ?? "[]",
    );

    const params = { ...storedParams, ...paramsObj };

    this.setLsWithExpiry("params", JSON.stringify(params), 1000 * 60 * 60 * 24);

    return params;
  }

  handleMd5Hash(): void {
    let md5 = localStorage.getItem("md5") || "";
    const localMd5Cookie = (this.getCookie("md5Hash") as string) ?? "";
    if (localMd5Cookie) {
      md5 = localMd5Cookie;
    }
    const qsMd5 = this.getQueryStringValue("md5");
    if (qsMd5) {
      md5 = qsMd5;
    }
    if (md5 && md5 !== "undefined") {
      this.setMd5(md5);
    }
    if (md5 === "undefined") {
      localStorage.removeItem("md5");
      deleteCookie("md5Hash");
    }
  }

  handleSha1Hash(): void {
    let sha1 = "";
    const storedIdentities = JSON.parse(this.getCookie("vp_identity") || "{}");
    const qs = this.getQueryStringValue("sha1");
    if (qs) {
      sha1 = qs;
    }
    if (sha1 && sha1 !== "undefined") {
      this.setSha1(sha1);
    }
  }

  handleSha256Hash(): void {
    let sha256 = "";
    const storedIdentities = JSON.parse(this.getCookie("vp_identity") || "{}");
    const qs = this.getQueryStringValue("sha256");
    if (qs) {
      sha256 = qs;
    }
    if (sha256 && sha256 !== "undefined") {
      this.setSha256(sha256);
    }
  }

  setLsWithExpiry(key: string, value: string, ttl: number): void {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it' supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  getLsWithExpiry(key: string): string | null {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (typeof item.expiry !== "undefined" && now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  get experiments(): iExperiment[] {
    return this._experiments;
  }

  set experiments(experiment: iExperiment) {
    this._experiments.push(experiment);
  }

  secondsOnSite() {
    return (Date.now() - Number(this.startTime)) / 1000;
  }

  secondsOnPage() {
    return (Date.now() - Number(this.startTimeOnPage)) / 1000;
  }

  async pageview(url: string) {
    if (!this.startTime) {
      this.startTime = Date.now();
    }
    this.startTimeOnPage = Date.now();
    const experimentCookiesArray = [] as iExperiment[];

    const cookies = this.getAllCookies();

    Object.keys(cookies).forEach((key) => {
      if (key.startsWith("ss_") && !key.startsWith("ss_override_")) {
        experimentCookiesArray.push({
          name: key.replace("ss_", ""),
          value: cookies[key],
        });
      }
    });

    this._experiments = experimentCookiesArray;

    this.history.push(url);

    await this.track(TRACKING_EVENTS.pageview, {
      location: location?.pathname.split("/")[1] || "home",
    });
  }

  async track(
    event: keyof TRACKING_EVENTS,
    properties: iTrackProperties = {} as iTrackProperties,
  ) {
    if (!properties.numPagesViewed) {
      properties.numPagesViewed = this.history.length;
    }

    if (!properties.secondsOnSite) {
      properties.secondsOnSite = this.secondsOnSite();
    }

    if (!properties.title) {
      properties.title = document.title;
    }

    if (!properties.referrer) {
      properties.referer = document.referrer || window.location.origin;
    }

    if (!properties.url) {
      properties.url = location.href;
    }

    if (!properties.md5 && this.md5 && this.md5 !== "undefined") {
      properties.md5 = this.md5;
    }

    properties.secondsOnPage = this.secondsOnPage();
    properties.secondsOnSite = this.secondsOnSite();
    properties.id = this.id;
    properties.session_id = this.session_id;
    properties.experiments = this.experiments;

    Object.keys(this.parameters).forEach((x) => {
      properties[x] = this.parameters[x];
    });

    const defaultKv = {
      cookie_id: "",
      cookie_isnew: false,
      event: getMappedEventType(event),
      sub_type: event,
      index: "",
      name: "",
      source_system: "",
      location: location?.pathname.split("/")[1] || "home",
    };

    const props = {
      e: event,
      kv: { ...defaultKv, ...properties },
      ts: new Date().toISOString(),
    };

    const url = `${this.endpoint}?data=${encodeURIComponent(
      JSON.stringify(props),
    )}`;

    try {
      const data = await fetch(url);
      log(
        { action: event },
        { location: location?.pathname.split("/")[1] || "home" },
        { destination: "JT" },
        {
          metadata: { ...properties },
        },
      );
      return data.json();
    } catch (ex) {
      console.error("failed to fetch clickserver", ex);
      return "";
    }
  }

  setMd5(hash: string) {
    this.md5 = hash;
    this.setCookie("md5Hash", hash);
    localStorage.setItem("md5", hash);
    const currentPt = (this.getCookie("_pt") as string) ?? "";
    const pt =
      currentPt !== "" ? JSON.parse(decodeURIComponent(currentPt)) : {};
    this.setCookie(
      "_pt",
      JSON.stringify({
        ...pt,
        md5: hash,
      }),
    );
  }

  setSha1(hash: string) {
    this.sha1 = hash;
  }

  setSha256(hash: string) {
    this.sha256 = hash;
  }

  setIdentity(identities: EmailHashes) {
    const currentIdentities = JSON.parse(this.getCookie("vp_identity") || "{}");
    if (identities.md5) {
      currentIdentities.md5 = identities.md5;
    }
    if (identities.sha1) {
      currentIdentities.sha1 = identities.sha1;
    }
    if (identities.sha256) {
      currentIdentities.sha256 = identities.sha256;
    }
    this.setCookie("vp_identity", JSON.stringify(currentIdentities));
    ezoicSetIdentity?.(currentIdentities);
  }

  setCookie(cname: string, cvalue: string, exdays = 365) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname: string) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getAllCookies() {
    const decodedList = decodeURIComponent(document.cookie).split(";");
    const cookies = {} as any;
    decodedList.forEach((c) => {
      const [key, value] = c.trim().split("=");
      cookies[key] = value;
    });
    return cookies;
  }

  getQueryStringValue(name: string) {
    return new URLSearchParams(location.search).get(name);
  }

  onWindowBlur() {
    setTimeout(() => {
      if (document.activeElement?.tagName === "IFRAME") {
        window.clickServer.iframeTracking(document.activeElement.id);
      }
    });
  }

  setIframeTracking(fn: () => void) {
    this.iframeTracking = fn;
  }
}

export class ClickServer {
  static init() {
    if (typeof window !== "undefined" && !window.clickServer) {
      window.clickServer = new EventTracker();
      window.addEventListener("blur", window.clickServer.onWindowBlur);
      setInterval(this.findIframes, 500);
    }
  }

  static setIframeTracking(fn: (id?: string) => void) {
    if (typeof window !== "undefined" && window.clickServer) {
      window.clickServer.setIframeTracking(fn);
    }
  }

  static pageView(url: string) {
    JT_PAGEVIEW_EVENTS.push(url);

    if (typeof window !== "undefined" && window.clickServer) {
      while (JT_PAGEVIEW_EVENTS.length) {
        window.clickServer.pageview(JT_PAGEVIEW_EVENTS.shift());
      }
    }
  }

  static setEmailHashes(hashes: EmailHashes) {
    if (typeof window !== "undefined" && window.clickServer) {
      while (JT_MD5_EVENTS.length) {
        window.clickServer.setHashes(hashes);
      }
    }
  }

  static setMd5(hash: string) {
    JT_MD5_EVENTS.push(hash);

    if (typeof window !== "undefined" && window.clickServer) {
      while (JT_MD5_EVENTS.length) {
        window.clickServer.setMd5(JT_MD5_EVENTS.shift());
      }
    }
  }

  static setSha1(hash: string) {
    if (typeof window !== "undefined" && window.clickServer) {
      while (JT_MD5_EVENTS.length) {
        window.clickServer.setSha256(hash);
      }
    }
  }

  static setSha256(hash: string) {
    if (typeof window !== "undefined" && window.clickServer) {
      while (JT_MD5_EVENTS.length) {
        window.clickServer.setSha1(hash);
      }
    }
  }

  static setIdentity(hashes: EmailHashes) {
    if (typeof window !== "undefined" && window.clickServer) {
      window.clickServer.setIdentity(hashes);
    }
  }

  static track(
    event: keyof TRACKING_EVENTS,
    properties: any = {},
    customOverrides: any = {},
  ) {
    JT_TRACK_EVENTS.push({
      event,
      properties: properties,
      customOverrides: customOverrides,
    });

    if (typeof window !== "undefined" && window.clickServer) {
      while (JT_TRACK_EVENTS.length) {
        const currentEvent = JT_TRACK_EVENTS.shift() as iTrackEvent;
        window.clickServer.track(
          currentEvent.event,
          Object.assign(
            {
              location:
                currentEvent.customOverrides?.location?.pathname?.split(
                  "/",
                )[1] ||
                location?.pathname.split("/")[1] ||
                "home",
            },
            currentEvent.properties,
            currentEvent.customOverrides,
          ),
        );
      }
    }
  }

  static extendProperties(
    propertyName: string,
    propertyValue: { name: string; value: string },
  ) {
    if (
      typeof window !== "undefined" &&
      window.clickServer &&
      window.clickServer[propertyName]
    ) {
      if (propertyValue.value) {
        const index = window.clickServer[propertyName].findIndex(
          (experiment: { name: string; value: string }) =>
            experiment.name === propertyValue.name,
        );
        index === -1 && window.clickServer[propertyName].push(propertyValue);
      }
    }
  }

  static findIframes() {
    Array.from(document.getElementsByTagName("iframe")).forEach(
      function (element) {
        element.onmouseover = window.clickServer.iframeMouseOver;
        element.onmouseout = window.clickServer.iframeMouseOut;
      },
    );
  }
}
